import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '../components/Layout'
import Text from '../components/common/Text'
import Space from '../components/common/Space'
import TextLink from '../components/common/TextLink'

const Wrapper = styled.div`
  padding: 150px 0px;
  @media (max-width: 1050px) {
    padding: 80px 0px;
  }
`

const NotFound = () => (
  <Layout>
    <Wrapper>
      <Text center heading1>
        Our apologies—this is no longer available.
      </Text>
      <Space height={24} />
      <Text center>
        We're so sorry this isn't the information you're looking for. Let's try getting you back to our{' '}
        <Link to="/">homepage</Link> to find what you need. You can also always access our{' '}
        <TextLink to="https://audioeye.zendesk.com/hc/en-us" text="Help Center" /> if you want to navigate to some of
        the most frequently asked questions and facts about digital accessibility.
      </Text>
    </Wrapper>
  </Layout>
)

export default withPrismicUnpublishedPreview(NotFound)
